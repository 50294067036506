import React from "react"
import Layout from "../components/layout/layout"
import Title from "../components/layout/Title"
import styled from "styled-components"
import SEO from "../components/layout/seo"

const Container = styled.div`
  padding-top: 100px;
  padding-bottom: 40px;
  width: 700px;
  margin: 0 auto;
`
const SubTitle = styled.h2`
  font-size: 1.2em;
  font-weight: 700;
  margin: 1em 0;
`

const Content = styled.div`
  padding-top: 2em;
`

const Paragraph = styled.p`
  line-height: 1.8;
`

const ExternalLink = styled.a`
  text-decoration: none;
  color: black;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 0.5;
  }
`

const About = () => {
  return (
    <Layout>
      <SEO
        title="aboutページ"
        description="ReactベースのNext.js, React, React Navtiveの情報を公開しているブログのaboutページです。"
        article={false}
      />
      <Container>
        <Title title="このサイトについて" />
        <Content>
          <SubTitle>公開内容</SubTitle>
          <Paragraph>
            プログラミング入門者向けのReact,
            JavaScriptに関する情報やReactベースのGatsbyJS,Next.jsなどの技術情報を公開しています。
          </Paragraph>
          <SubTitle>個人情報の利用について</SubTitle>
          <Paragraph>
            本サイトでは個人情報の収集は一切行っておりません。
            お問い合わせメールなどの個人情報は、本人の同意なく公開、第三者への提供は一切行いません。
            <br />
            アクセス解析ツールやアフェリエイトプログラム、広告配信サービスを利用しているため、Cookieを使って情報を取得する場合がございます。Cookieが記憶する情報には個人を特定するものは一切入っておりません。また、Cookieにより情報の取得を拒否したい場合は、ユーザ自身がブラウザでCookieを無効にすることで受け入れを拒否することも可能です。Cookieを無効にした場合にサイトが正常に表示されない場合もございますので、あらかじめご了承ください。
          </Paragraph>
          <SubTitle>免責事項</SubTitle>
          <Paragraph>
            当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。
            <br />
            当サイトのコンテンツ・情報につきまして実際に実機を用いて動作確認を行い掲載しておりますが入力間違い等や情報が古くなっていることもございます。
            <br />
            当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
          </Paragraph>
          <SubTitle>サイト運営者</SubTitle>
          <Paragraph>
            Reffect
            <br />
            TwitterName:
            <ExternalLink href="https://reffect.co.jp">
              @reffect2020
            </ExternalLink>
            <br />
            URL:
            <ExternalLink href="https://reffect.co.jp">
              https://reffect.co.jp
            </ExternalLink>
          </Paragraph>
        </Content>
      </Container>
    </Layout>
  )
}

export default About
